<script>
	import IndexFooter from "src/components/indexFooter.svelte";
	import Header from "./header.svelte";
	import Hero from "./hero.svelte";
	import Identificate from "./identificate.svelte";
	import IntroCard from "./intro-card.svelte";
	import Testimonials from "./testimonials.svelte";
</script>

<div class="relative w-full overflow-x-hidden">
	<Header />
	<Hero />
	<IntroCard />
	<Testimonials />
	<Identificate />
	<IndexFooter />
</div>
