<script lang="ts">
	import userStore from "src/stores/user";

	async function login() {
		await userStore.loginWithPopup();
	}
</script>

<header class="z-20 w-full lg:absolute">
	<div class="container mx-auto flex flex-row items-center justify-between p-8">
		<a href="https://txm.business/" target="_blank">
			<img alt="" src="/img/index/header/logo_txm.svg" />
		</a>
		<button class="button px-10 text-think-500" on:click={login}>Login</button>
	</div>
</header>
