<div class="container mx-auto flex w-full">
	<div
		class="shadow-full z-[1] mx-8 flex flex-col items-center rounded-[2.5rem]
        border-2 border-neutral-50/30 bg-neutral-100/30"
	>
		<span
			class="p-12 text-center text-base font-semibold md:text-2xl
            xl:text-4xl xl:font-bold"
		>
			Nossa <span class="text-think-500">metodologia</span> foi pensada para
			<span class="text-think-500">desenvolver o empreendedorismo</span>
			com foco na <span class="text-think-500">cocriação</span> e estrutura flexível,
			com abordagem baseada em ferramentas de gestão, ux, design, comunicação e marketing.
		</span>

		<div class="intro-bg relative mt-4 flex min-h-[35vw] w-full">
			<div class="mx-6 text-center lg:ml-14 lg:w-2/5 lg:text-left xl:p-10">
				<div class="mb-5 flex flex-row items-center justify-between lg:hidden">
					<div class="relative">
						<img
							class=""
							alt="TXM Methods"
							src="/img/sections/experience.png"
						/>
						<picture
							class="absolute left-[-100%] top-[-100%] -z-10 w-[300%] lg:hidden"
						>
							<source
								srcset="/img/index/intro-bg/blur-experience.webp"
								type="image/webp"
							/>
							<source
								srcset="/img/index/intro-bg/blur-experience.png"
								type="image/png"
							/>
							<img
								class="relative h-full w-full"
								alt=""
								src="/img/index/intro-bg/blur-experience.png"
							/>
						</picture>
					</div>
					<div class="relative">
						<img class="" alt="TXM Methods" src="/img/sections/manage.png" />
						<picture
							class="absolute left-[-100%] top-[-100%] -z-10 w-[300%] lg:hidden"
						>
							<source
								srcset="/img/index/intro-bg/blur-manage.webp"
								type="image/webp"
							/>
							<source
								srcset="/img/index/intro-bg/blur-manage.png"
								type="image/png"
							/>
							<img
								class="relative h-full w-full"
								alt=""
								src="/img/index/intro-bg/blur-manage.png"
							/>
						</picture>
					</div>
					<div class="relative">
						<img class="" alt="TXM Methods" src="/img/sections/think.png" />
						<picture
							class="absolute left-[-100%] top-[-100%] -z-10 w-[300%] lg:hidden"
						>
							<source
								srcset="/img/index/intro-bg/blur-think.webp"
								type="image/webp"
							/>
							<source
								srcset="/img/index/intro-bg/blur-think.png"
								type="image/png"
							/>
							<img
								class="relative h-full w-full"
								alt=""
								src="/img/index/intro-bg/blur-think.png"
							/>
						</picture>
					</div>
				</div>
				<span
					class="text-base font-medium md:text-lg xl:text-2xl xl:font-semibold"
				>
					A TXM Business possui três etapas:
					<span class="text-think-500">Think</span>,
					<span class="text-experience-500">EXperience</span>
					e <span class="text-manage-500">Manage</span>.<br />
					Sua abordagem é não-linear, ou seja, é possível iniciar utilizando as ferramentas
					mais adequadas a cada projeto, voltar e avançar de acordo com o desenvolvimento
					do negócio.
				</span>
			</div>
			<picture
				class="absolute left-[22%] top-[-42%] -z-10 hidden w-[70%] lg:block"
			>
				<source
					srcset="/img/index/intro-bg/blur-think.webp"
					type="image/webp"
				/>
				<source srcset="/img/index/intro-bg/blur-think.png" type="image/png" />
				<img
					class="relative h-full w-full"
					alt=""
					src="/img/index/intro-bg/blur-think.png"
				/>
			</picture>
			<picture
				class="absolute left-[50%] top-[-11%] -z-10 hidden w-[70%] lg:block"
			>
				<source
					srcset="/img/index/intro-bg/blur-experience.webp"
					type="image/webp"
				/>
				<source
					srcset="/img/index/intro-bg/blur-experience.png"
					type="image/png"
				/>
				<img
					class="relative h-full w-full"
					alt=""
					src="/img/index/intro-bg/blur-experience.png"
				/>
			</picture>
			<picture class="absolute left-0 top-0 -z-10 hidden w-[70%] lg:block">
				<source
					srcset="/img/index/intro-bg/blur-manage.webp"
					type="image/webp"
				/>
				<source srcset="/img/index/intro-bg/blur-manage.png" type="image/png" />
				<img
					class="relative h-full w-full"
					alt=""
					src="/img/index/intro-bg/blur-manage.png"
				/>
			</picture>
		</div>

		<div class="info-container mt-8 flex-col lg:mt-20 lg:flex-row">
			<div class="info-logo-container lg:w-2/5">
				<img class="w-2/3" alt="TXM Methods" src="/img/index/txm-logo.svg" />
				<a
					class="button info-logo-button lg:w-2/3"
					href="https://txm-methods.com"
					rel="noopener noreferrer"
					target="_blank"
				>
					Conheça a TXM Methods
				</a>
			</div>
			<div class="py-4 lg:w-3/5">
				<h1 class="mb-5 text-center text-4xl font-bold lg:text-left">
					A TXM Methods
				</h1>
				<span class="text-xl font-bold leading-relaxed">
					A metodologia foi desenvolvida pela TXM Methods, uma empresa de
					desenvolvimento de métodos e processos para auxiilar empreendedores,
					organizações públicas e privadas, e instituições acadêmicas a
					inovarem.
				</span>
			</div>
		</div>

		<div class="info-container mb-10 flex-col lg:flex-row">
			<div class="info-logo-container lg:w-2/5">
				<img
					class="w-2/3"
					alt="TXM Methods"
					src="/img/index/cocreation-logo.svg"
				/>
				<a
					class="button info-logo-button lg:w-2/3"
					href="https://cocreationlab.com.br/"
					rel="noopener noreferrer"
					target="_blank"
				>
					Conheça o Cocreation Lab
				</a>
			</div>
			<div class="py-4 lg:w-3/5">
				<h1 class="mb-5 text-center text-4xl font-bold lg:text-left">
					Laboratórios
				</h1>
				<span class="text-xl font-bold leading-relaxed">
					Nossos métodos são aplicados no maior laboratório de ideação do país,
					o Cocreation lab, que auxilia pessoas físicas a construírem um novo
					negócio.
				</span>
			</div>
		</div>
	</div>
</div>

<style>
	.shadow-full {
		box-shadow:
			5px 5px 18px rgba(0, 0, 0, 0.1),
			inset 2px 6px 27px rgba(0, 0, 0, 0.1);
	}
	@media (min-width: 1280px) {
		.intro-bg {
			background-image: url("/img/index/intro-bg.svg");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.info-container {
		@apply flex w-full p-10 text-neutral-700;
	}
	.info-logo-container {
		@apply flex flex-col items-center  self-center p-4;
	}
	.info-logo-button {
		@apply mt-6  text-lg;
	}
</style>
